import React from "react"
import Layout from "../components/layout"
import { useTranslation } from "gatsby-plugin-react-i18next"

import SEO from "../components/seo"

const PrivacyPolicy = () => {
  const { t } = useTranslation()

  return (
    <Layout location="/privacy-policy" swiper={false}>
      <SEO title={t("privacy_policy_title")} />
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h3 class="my-3">{t("privacy_policy_title")}</h3>
            <a
              className="button button-primary button-ujarak"
              href="/docs/privacy-policy.pdf"
            >
              {t("privacy_policy_title")}
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PrivacyPolicy

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
